@import "src/assets/scss/colors.scss";

.configurator {
  color: $alice-blue;
  align-self: center;
  text-align: center;
  font-size: 16pt;
  list-style-type: disc;
  padding: 3%;
}

.configurationFields {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  justify-items: center;
  align-content: center;
}

.completeConfiguration {
  background-color: $honululu-blue;
  width: 60%;
  padding: 2%;
  height: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
