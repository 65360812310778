@import "src/assets/scss/colors.scss";

.graph {
  justify-content: center;
  justify-items: center;
  display: inline-flex;
  width: 100%;
  height: 90%;
}

.drawing {
  justify-content: center;
  justify-items: center;
  align-content: center;
  font-size: 12pt;
  fill: aquamarine;
}
