@import "src/assets/scss/colors.scss";

.slider {
  width: 300px;
  appearance: none;
  background: $alice-blue;
  accent-color: $poppy;
  cursor: pointer;
  height: 0.5rem;
  border-radius: 4px;
}

.slider::-webkit-slider-runnable-track {
  color: $davys-gray;
}

.container {
  justify-items: center;
  justify-content: center;
  align-items: center;
  align-content: center;
  align-self: center;
  display: block;
  color: $alice-blue;
  text-align: center;
}

.label {
  padding-top: 6%;
  font-size: 14pt;
}

.volume {
  font-size: 20pt;
  color: $alice-blue;
  text-align: center;
}
