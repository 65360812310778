@import "src/assets/scss/colors.scss";

.variables {
  width: 100%;
  color: $alice-blue;
  text-align: center;
  font-size: 16pt;
  height: 10%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.container {
  background-color: $honululu-blue;
  width: 60%;
  height: 80%;
  padding: 2%;
  align-content: center;
  justify-content: center;
  align-items: center;
  justify-items: center;
}

.label {
  padding: 1%;
}
