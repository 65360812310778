@import "src/assets/scss/colors.scss";

.toolbar {
  width: 100%;
  height: 220px;
  display: inline-flex;
  background-color: $poppy;
  justify-items: center;
  justify-content: center;
  align-items: center;
}
