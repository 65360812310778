@import "src/assets/scss/colors.scss";

.container {
  justify-items: center;
  display: block;
  align-content: center;
  align-self: center;
  justify-self: center;
  padding-top: 2%;
}

.input {
  background-color: $alice-blue;
  color: $honululu-blue;
  font-size: 14pt;
  border-radius: 20px;
  text-align: center;
  border-style: none;
  width: 100%;
}

.label {
  font-size: 14pt;
  text-align: center;
  align-self: center;
  text-wrap: nowrap;
  text-indent: none;
  width: 100%;
}
