@import "src/assets/scss/colors.scss";

.project {
  display: inline-flex;
  align-items: flex-start;
  width: 100%;
  background-color: $alice-blue;
  height: calc(100% - 220px);
}

.rightSide {
  display: block;
  justify-items: center;
  width: 100%;
  height: 100%;
  background-color: $alice-blue;
}

.half {
  height: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
